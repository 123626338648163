<template>
  <div>
    <v-simple-table class="table-padding-2-no-top table-h-36">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr class="fs-16px primary--text">
            <th colspan="7" class="fs-16px primary--text">Bán thành phẩm</th>
          </tr>
        </thead>
        <tbody>
          <tr class="font-weight-medium text-center">
            <td>Danh mục</td>
            <td>Chi tiết</td>
            <td>{{ $t("labels.quantity") }}</td>
            <td colspan="3">Cấp cho</td>
            <td style="width: 100px">
              <v-btn
                v-if="item.level === 0"
                x-small
                color="primary"
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </td>
          </tr>
          <tr v-for="(val, index) in items" :key="val.id" class="text-center">
            <template v-if="!val.id">
              <td>
                <v-autocomplete
                  class="c-input-small"
                  v-model="val.id_category"
                  :items="categoryOptions"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <v-autocomplete
                  class="c-input-small"
                  v-model="val.id_semi_finished"
                  :items="semiFinishedOptions"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  class="c-input-small"
                  v-model.number="val.quantity"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
            </template>

            <template v-else>
              <td>{{ val.category_name }}</td>
              <td>{{ val.semi_finished_name }}</td>
              <td>{{ val.quantity }}</td>
            </template>

            <td class="text-center vertical-align-middle" style="width: 100px">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="val.for_employee"
                  dense
                  :disabled="!val.editing"
                  class="mt-0 ml-1 c-input-small"
                  :value="1"
                  hide-details
                  label="Nhân viên"
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle" style="width: 100px">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="val.for_machiner"
                  dense
                  :disabled="!val.editing"
                  class="mt-0 ml-1 c-input-small"
                  :value="1"
                  hide-details
                  label="Gia công"
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle" style="width: 100px">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="val.for_servicer"
                  dense
                  :disabled="!val.editing"
                  class="mt-0 ml-1 c-input-small"
                  :value="1"
                  hide-details
                  label="Dịch vụ"
                ></v-checkbox>
              </div>
            </td>
            <td>
              <template v-if="val.editing">
                <v-btn x-small color="success" @click="save(val)" class="mr-1">
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(val, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(val, index, true)"
                  class="mr-1"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  v-if="item.level === 0"
                  @click="deactiveItem(val)"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { INDUSTRY_MATERIAL_ID } from "@/libs/const";
import { debounce } from "lodash";

export default {
  name: "RecordDetailSemiFinished",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    semiFinished: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isLoading: false,
    categoryOptions: [],
    items: [],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    semiFinishedOptions() {
      return [...this.semiFinished].map((sf) => ({
        text: sf.name,
        value: sf.id,
      }));
    },
  },
  created() {},
  mounted() {
    this.getList();
    this.getCategories();
  },
  methods: {
    getList: debounce(function () {
      httpClient
        .post("/goods-production-semi-finished-list", {
          id_goods_production_record: this.item.id,
        })
        .then(({ data }) => {
          this.items = [...data];
          this.$emit("updateRecordSemiFinished", this.items);
        });
    }, 500),

    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },

    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_semi_finished: null,
        id_category: null,
        quantity: null,
        for_employee: 0,
        for_machiner: 0,
        for_servicer: 0,
        editing: true,
      });
    },

    async getCategories() {
      const { data } = await httpClient.post("/category-by-customer", {
        id_industry: INDUSTRY_MATERIAL_ID,
      });
      this.categoryOptions = data.map((e) => ({
        value: e.id,
        text: e.name || "",
      }));
    },

    async save(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-semi-finished-save", {
          id_goods_production_record: this.item.id,
          id: item.id,
          id_category: item.id_category,
          id_semi_finished: item.id_semi_finished,
          quantity: item.quantity,
          for_employee: item.for_employee,
          for_machiner: item.for_machiner,
          for_servicer: item.for_servicer,
        });
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async deactiveItem(item) {
      if (!confirm(this.$t("messages.are_you_sure_delete"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-record-deactive-detail", {
          id_goods_production_record: this.item.id,
          id: item.id,
          type: "semi_finished",
        });
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
